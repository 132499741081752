import '../../App.css'
import { Fragment, useEffect, useRef, useState, useContext } from 'react';
import { Modal, Popover, Button } from 'antd';
import { LuClock9 } from "react-icons/lu";
import { SlScreenDesktop } from "react-icons/sl";
import { FaCircle } from "react-icons/fa";
import Winner from '../../components/winner';
import MatchOdds from '../../components/matchOdds';
import TiedMatch from '../../components/tiedMatch';
import Bookmaker from '../../components/bookmaker';
import WinTheMatch from '../../components/win-the-match';
import Fancy from '../../components/fancy';
import Sportsbook from '../../components/sportsbook';
import { json, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Appconfig from '../../config/config'
import '../../../src/styles.css';

// import { CasinoWebSocketContext } from '../../context/casinoWebsocket';

import { useSelector, useDispatch } from 'react-redux';
import { setData } from '../../redux/slice/betting/bettingSlice';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
export default function DRAGONTIGER1DAY() {
    // const casinowebsocket = useContext(CasinoWebSocketContext);
    // console.log('casinowebsocket', casinowebsocket);

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const { casino_id } = useParams();
    const userInfo = JSON.parse(localStorage.getItem('userdata'))
    const [loadCasino, setLoadCasino] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);
    // console.log('casinoData', casinoData);
    const [casinoData, setCasinoData] = useState([]);
    const [casinoEventId, setCasinoEventId] = useState('');
    const [casinoRoundId, setCasinoRoundId] = useState('');
    const [casinoCardRoundId, setCasinoCardRoundId] = useState('');


    const [marketState, setMarketState] = useState([]);
    const [cardsState, setCardsState] = useState('');
    const [resultState, setResultState] = useState([]);
    const [casinoStatus, setCasinoStatus] = useState('');

    // console.log('casinoStatus', casinoStatus);

    const [winnerName, setWinnerName] = useState('');

    const [showBetsSlip, setShowBetsSlip] = useState(false);
    const [chips, setChips] = useState([]);
    const [placing, setPlacing] = useState(false);
    const [type, setType] = useState('back')
    const [selection_id, setSelection_id] = useState('')
    const [market_id, setMarket_id] = useState('')


    const [htmlId, setHtmlId] = useState('')
    const [BetPlaceData, setBetPlaceData] = useState({
        event_id: "",
        round_id: "",
        market_id: "",
        is_back: "",
        price: "",
        is_fancy: "",
        selection_id: "",
        runner_name: "",
        market_name: "",
        PM_FANCY: false
    });
    const [ProfitValue, setProfitValue] = useState(0);
    const [lossValue, setLossValue] = useState(0);
    const [StakeValue, setStakeValue] = useState(0);
    const [size, setSize] = useState('')
    const [is_fancy, setIs_fancy] = useState(false)
    const [defaultStake, setDefaultStake] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [position, setCasinoPosition] = useState([])
    const [cardType, setCardType] = useState('DRAGON CARD')
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        setTimeout(() => {
            setLoadCasino(false);
        }, 1000)
    })
    const [seconds, setSeconds] = useState(45);
    useEffect(() => {
        if (seconds > 0) {
            const interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setSeconds(45);
        }
    }, [seconds]);

    useEffect(() => {
        const casinowebsocket = new WebSocket("wss://ws.operator.buzz:9093/");
        if (casinowebsocket) {


            casinowebsocket.onopen = () => {
                console.log('WebSocket connection opened');
                let sendReq = {
                    'event_id': Appconfig.casino_type_id['1daydt'],
                }
                casinowebsocket.send(JSON.stringify({ action: 'JOIN', data: sendReq }));

                // Send a test message to the server (if applicable)
            };




            casinowebsocket.onmessage = function (message) {
                const casinos = JSON.parse(message?.data);

                if (casinos?.action == "MARKET_UPDATE" && casinos?.event_type == Appconfig.casino_type_id['1daydt']) {
                    let casinoss = casinos?.data;


                    console.log('casinoss', casinoss);
                    if (casinoss) {


                        if (casinoss?.skyOther) {

                            setCasinoData(casinoss?.skyOther[0]?.data)
                        }
                        // setCasinoEventId(casinoss?.skyOther[0]?.eventId)
                        // setCasinoRoundId(casinoss?.skyOther[0]?.roundId)

                        // console.log('status', casinoss?.skyPlaceBet[0]?.data.status);
                        if (casinoss?.skyPlaceBet) {

                            if (casinoss?.skyPlaceBet[0]?.data.status) {
                                setCasinoStatus(casinoss?.skyPlaceBet[0]?.data.status);
                                setMarketState(casinoss?.skyPlaceBet[0]?.data?.marketArr);
                                setCasinoEventId(casinoss?.skyPlaceBet[0]?.eventId)
                                setCasinoRoundId(casinoss?.skyPlaceBet[0]?.roundId)

                                if (casinoss?.skyPlaceBet[0]?.data.status == "SUSPEND") {
                                    setShowBetsSlip(false);
                                }
                            }
                        }


                        if (casinoss?.skyCardScan) {
                            if (casinoss?.skyCardScan[0]?.data?.cardsArr) {
                                setCardsState(casinoss?.skyCardScan[0].data?.cardsArr);
                                // setCasinoEventId(casinoss?.skyCardScan[0]?.eventId)
                                setCasinoCardRoundId(casinoss?.skyCardScan[0]?.roundId)

                            }
                        }

                        if (casinoss?.skyResultDeclared) {
                            if (casinoss?.skyPlaceBet[0]?.roundId == casinoss?.skyResultDeclared[0]?.roundId) {
                                setResultState(casinoss?.skyResultDeclared[0]?.data?.resultsArr);
                                getWinnerName(casinoss?.skyResultDeclared[0]?.data?.resultsArr);

                            } else {
                                setWinnerName('');
                            }
                        }

                    }

                }



            }



            casinowebsocket.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            casinowebsocket.onclose = (event) => {
                console.log('WebSocket connection closed:', event);
            };


        }

    }, []);


    function getWinnerName(resultsArr) {
        // Find the market with the name "WINNER"
        const winnerMarket = resultsArr.find(market => market.marketName === "WINNER");

        if (!winnerMarket) {
            return "No winner market found";
        }

        // Find the runner ID associated with the "WINNER"
        const winnerRunnerId = Object.keys(winnerMarket.runners).find(runnerId => winnerMarket.runners[runnerId] === "WINNER");

        if (!winnerRunnerId) {
            return "No winner found";
        }

        // Get the winner name using the runner ID
        const winnerName = winnerMarket.runnersName[winnerRunnerId];

        setWinnerName(winnerName + ' Win');
    }



    useEffect(() => {
        if (userInfo) {
            getChips();
            getAuraCasinoPosition();
        }
    }, [])

    function getAuraCasinoPosition() {
        var data = JSON.stringify({
            user_id: userInfo?._id,
            event_type: Appconfig.casino_type_id['1daydt']
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/getAuraCasinoPosition`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                // console.log('response', response);

                if (response.data.result) {
                    let casinoPos = response.data.resultData;
                    if (casinoPos.length > 0) {
                        casinoPos.forEach(element => {
                            var elements = document.getElementsByClassName(`selection_position_${element.selection_id}`);
                            // console.log('elements', elements);
                            for (var el of elements) {
                                el.innerHTML = Math.abs(element.total_pl.toFixed(2));
                                el.style.color = element.total_pl > 0 ? 'green' : 'red';
                            }

                        });
                        setCasinoPosition(response.data.resultData)

                    }

                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    function getChips() {
        if (userInfo) {
            var data = JSON.stringify({
                user_id: userInfo._id,
            });
            var config = {
                method: "post",
                url: `${Appconfig.apiUrl}chips/getChips`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };
            axios(config)
                .then(function (response) {
                    try {
                        if (response.status) {
                            setChips(response.data)
                        }
                    } catch (e) {
                        console.log(e)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            navigate('/login')
        }
    }



    const handlePriceValue = (priceVal) => {
        if (String(priceVal).startsWith("NaN")) {
            priceVal = String(priceVal).replace("NaN", "");
        }
        priceVal = parseFloat(priceVal).toFixed(2);
        setBetPlaceData({
            ...BetPlaceData,
            price: parseFloat(priceVal).toFixed(2)
        });
        calc(StakeValue, priceVal, BetPlaceData.selection_id)
        SetPosition(StakeValue, priceVal, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id);
    };

    function SetPosition(stake, priceVal, market_id, is_back, selection_id, market_name) {
        priceVal = parseFloat(priceVal)
        var MarketId = market_id;
        var MId = MarketId;
        var selectionId = selection_id;
        var isback = is_back;
        stake = parseFloat(stake);
        let MatchMarketTypes = "";
        var runners = document.getElementsByClassName("runners_" + MId);
        // console.log('runners', runners);
        // return false;
        var tempRunners = "";
        for (var item of runners) {
            var selecid = item.getAttribute('data-id');
            var winloss = 0
            // winloss = parseFloat(item.value);
            var curr = 0;
            // console.log({
            //     'selecid': selecid,
            //     'selectionId': selectionId,
            //     'market_name': market_name,
            //     'priceVal': priceVal,
            //     'stake': stake,
            //     'winloss': winloss,
            //     'isback': isback,
            // });

            if (selectionId == selecid) {
                if (market_name == "WINNER") {
                    if (isback) {
                        // if (MatchMarketTypes == 'M') {
                        //     curr = winloss + ((priceVal * stake) / 100);
                        // } else {
                        curr = winloss + ((priceVal * stake) - stake);
                        // }
                    } else {
                        // if (MatchMarketTypes == 'M') {
                        //     curr = winloss + (-1 * parseFloat((priceVal * stake) / 100));
                        // } else {
                        curr = winloss + (-1 * parseFloat((priceVal * stake) - stake));
                        // }
                    }
                } else {
                    curr = winloss + ((priceVal * stake) - stake);
                }

            } else {
                if (isback == 1) {
                    curr = winloss + (-1 * (stake));
                } else {
                    curr = winloss + stake;
                }
            }
            var currV = curr;

            var elements = document.getElementsByClassName(`position_${MId}_${selecid}`);
            // console.log('elements', elements);
            for (var el of elements) {
                el.innerHTML = Math.abs(currV.toFixed(2));
                el.style.color = currV > 0 ? 'green' : 'red';
            }

        }
    }


    function calc(t_stake, priceVal, selection_id) {
        var isfancy = BetPlaceData.is_fancy;
        priceVal = parseFloat(priceVal);
        t_stake = parseFloat(t_stake);
        var isback = BetPlaceData.is_back
        if (!isfancy) {
            var pl = ((priceVal * t_stake) - t_stake);

            pl = parseFloat(pl.toFixed(2));
            if (isback) {
                setProfitValue(pl)
                setLossValue(t_stake)
            } else {
                setLossValue(pl)
                setProfitValue(t_stake)
            }
            // SetPosition(priceVal);
        }
    }

    const placeStakeValue = (stake) => {
        if (String(stake).startsWith("NaN")) {
            stake = String(stake).replace("NaN", "");
        }
        setStakeValue(parseFloat(stake));
        calc(stake, BetPlaceData.price, BetPlaceData.selection_id)
        SetPosition(stake, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id, BetPlaceData.market_name);
    };

    const cancelBetSlip = () => {
        SetPosition(0, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id, BetPlaceData.market_name);

        setShowBetsSlip(false);
        setSelection_id('');
        setHtmlId('');
    }


    const handleOpenBetSlip = (
        event_id,
        round_id,
        market_id,
        is_back,
        size,
        is_fancy,
        selection_id,
        runner_name,
        htmlId,
        PM_FANCY = null,
        market_name
    ) => {
        setShowBetsSlip(true);
        setSelection_id(selection_id)
        setMarket_id(market_id)
        setType(is_back ? 'back' : 'lay')
        setHtmlId(htmlId)
        setSize(size)
        setIs_fancy(is_fancy)
        // console.log({
        //     'event_id': event_id,
        //     'market_id': market_id,
        //     'is_back': is_back,
        //     'size': size,
        //     'is_fancy': is_fancy,
        //     'selection_id': selection_id,
        //     'runner_name': runner_name,
        //     'new_price': htmlId,
        //     'PM_FANCY': PM_FANCY,
        //     'market_name': market_name,
        // })

        // console.log('yes ')

        let selectElements = document.getElementsByClassName('market_runners_' + market_id.replace('.', '') + '_' + selection_id);


        setProfitValue(0);
        setStakeValue(0)
        var priceNew = htmlId;

        setBetPlaceData({
            event_id: event_id,
            round_id: round_id,
            market_id: market_id,
            is_back: is_back ? 1 : 0,
            price: priceNew,
            is_fancy: is_fancy,
            selection_id: selection_id,
            runner_name: runner_name,
            market_name: market_name,
            PM_FANCY: PM_FANCY
        });
        setTimeout(function () {
            // SetPosition(0, priceNew, market_id, is_back, selection_id, market_name)
            calc(0, priceNew, selection_id)
            placeStakeValue(defaultStake);
        }, 800);
    }




    function betPlace(ishow) {

        if (userInfo) {
            if (userInfo.user_type == "User") {


                if (StakeValue > 99) {

                    setPlacing(true);
                    setIsLoading(true);

                    var data = JSON.stringify({
                        user_id: userInfo._id,
                        match_id: BetPlaceData.event_id,
                        round_id: BetPlaceData.round_id,
                        selection_id: BetPlaceData.selection_id,
                        is_back: BetPlaceData.is_back,
                        stake: StakeValue,
                        price_val: BetPlaceData.price,
                        market_id: BetPlaceData.market_id,
                        is_fancy: "No",
                        market_name: BetPlaceData.market_name,
                        runner_name: BetPlaceData.runner_name,
                        event_name: "Teenpatti 20-20",
                        profit: ProfitValue,
                        loss: lossValue,
                        pm_fancy: BetPlaceData.PM_FANCY,
                        event_type: Appconfig.casino_type_id['1daydt'],
                        is_casino: 'Yes'
                    });


                    var config = {
                        method: "post",
                        url: `${Appconfig.apiUrl}betting/auraAddBetting`,
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: data,
                    };
                    // alert("success");
                    axios(config)
                        .then(function (response) {

                            dispatch(setData(new Date()));

                            try {
                                setPlacing(false);
                                setSelection_id('')
                                setHtmlId('')
                                setProfitValue(0);
                                setStakeValue(0);
                                setShowBetsSlip(false)


                                if (response.data.result == 0) {
                                    // console.log('response:', response.data.resultMessage)
                                    NotificationManager.error(response.data.resultMessage, '', 3000);
                                } else {
                                    NotificationManager.success(response.data.resultMessage, '', 3000);

                                }
                                var MId = BetPlaceData.market_id;
                                var selectionId = BetPlaceData.selection_id;
                                var runners = document.getElementsByClassName("runners_" + MId);


                                for (var item of runners) {
                                    var selecid = item.getAttribute('data-id');

                                    var elements = document.getElementsByClassName(`position_${MId}_${selecid}`);

                                    for (var el of elements) {
                                        el.innerHTML = '';
                                    }

                                }

                                setBetPlaceData({
                                    event_id: "",
                                    round_id: "",
                                    market_id: "",
                                    is_back: "",
                                    price: "",
                                    is_fancy: "",
                                    selection_id: "",
                                    runner_name: "",
                                    market_name: "",
                                });
                                getAuraCasinoPosition();
                            } catch (e) {
                                console.log(e.message)
                            }
                            setIsLoading(false);

                        })
                        .catch(function (error) {
                            console.log(error);
                            console.log(error.message)
                            setIsLoading(true);

                        });
                }
                else {
                    NotificationManager.error('Min Stack Value Is 100', '', 3000);
                }

            }
        }
        else {
            navigate('/login')
        }
    }

    const CustomContent = ({ market }) => {
        return (
            <span className='lg:block text-[.75rem] font-bold text-[#ffffff]'>
                Min/Max: {market?.min + ' - ' + market?.max}
            </span>
        );
    };

    return (
        <>
            {
                loadCasino ?
                    <div className='block absolute w-full left-0 top-0 h-screen overflow-hidden'>
                        <div className='fixed top-0 relative w-full h-screen flex justify-center items-center bg-[#000000] z-[99999]'>
                            <img src='/loader.gif' className='w-[350px]' />
                        </div>
                    </div>
                    :
                    <div className='grid grid-cols-12 relative top-[56px] lg:top-0'>
                        <div className='col-span-12 relative flex'>
                            <video id="remoteVideo" width="100%" height="100%" autoplay="" playsinline="" muted="" style={{ backgroundColor: 'black', backgroundSize: 'contain' }}></video>
                            <div className='absolute bg-[rgba(0,_0,_0,_.4)] h-auto left-[0] content-[""] p-[3px]'>
                                <div className='flex flex-col'>
                                    <div className="font-bold text-[#fff] !text-[10px]">RID: {casinoRoundId}</div>
                                    <div className='flex flex-col'>
                                        <span className='leading-[9px] font-bold mb-0 mt-0 uppercase w-full !text-[12.5px] pb-[3px] pt-[3px] text-[#ffffff]'>DRAGON</span>
                                        <div className='flex'>
                                            <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoStatus == "SUSPEND" && casinoCardRoundId == casinoRoundId ? cardsState?.DRAGON?.card_1 : '0'}.png`}></img>
                                        </div>
                                    </div>
                                    <div className='flex flex-col pt-[3px]'>
                                        <span className='leading-[9px] font-bold mb-0 mt-0 uppercase w-full !text-[12.5px] pb-[3px] pt-[3px] text-[#ffffff]'>TIGER</span>
                                        <div className='flex'>
                                            <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoStatus == "SUSPEND" && casinoCardRoundId == casinoRoundId ? cardsState?.TIGER?.card_1 : '0'}.png`}></img>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                winnerName &&
                                <div className="winnerOverlay absolute h-auto bottom-2/4 ml-auto mr-auto p-[5px] w-full flex justify-center">
                                    <div className="mini-result-new active border-[0] border-[solid] mb-0 text-[#000] bg-[linear-gradient(-180deg,_#fd8f3b_0%,_#fd3523_100%)] w-auto text-[14px] p-[6px] uppercase rounded-tl-[16px] rounded-br-[16px] rounded-tr-[2px] rounded-bl-[2px] min-h-[20px] [transition:all_.5s_cubic-bezier(.168,-.885,.3,2)_.5s] scale-0 scale-100">
                                        <span> {winnerName}</span>
                                    </div>
                                    <span className='absolute' >
                                        <canvas width="100vw" height="100vh">
                                        </canvas>
                                    </span>
                                </div>
                            }


                            <div className="timer-overlay  ">
                                <div className='setting-btn text-white text-[1rem]'>
                                    <i className="fa fa-cog fa-lg text-[#ff8100]"></i>
                                </div>
                                <div className="timer-group">
                                    <div className="timer minute">
                                        <div className="hand">
                                            <span className="">
                                            </span>
                                        </div>
                                        <div className="hand">
                                            <span className="">
                                            </span>
                                        </div>
                                    </div>
                                    <div className="face">
                                        <p id="lazy" className="">{seconds}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='col-span-12 h-[calc(101vh-40vh)] lg:h-auto overflow-auto'>
                            <div className='grid-cols-12'>
                                <div id="marketScroll" className="overflow-y-auto overflow-hidden">
                                    <div>
                                        <ol className='rounded-[100vmax] m-0 p-0 [list-style:none] [counter-reset:liCount_7] grid gap-[calc(.5rem*2)]'>
                                            <div className="text-[16px] pl-[42px] pr-[50px] relative top-[9px] font-bold !justify-between !flex">
                                                {
                                                    marketState && marketState?.length > 0 && marketState?.map((market, i) => (
                                                        market?.marketName === "WINNER" && market?.runners && market?.runners?.length && market?.runners?.map((runner, i) => (
                                                            <span> {market?.runnersName[runner?.selectionId]} </span>
                                                        ))
                                                    ))
                                                }
                                            </div>
                                            <li className="m-[.5rem] mb-0 mt-0 bg-[#f2f2f2] text-[#0d0d0d] items-center gap-[calc(.5rem*4)] [content-increment:liCount] relative [box-shadow:calc(.5rem_*_.25)_calc(.5rem_*_.25)_.5rem_rgba(0,_0,_0,_.5)] rounded-[100vmax] !justify-between flex ">

                                                {
                                                    marketState && marketState.length > 0 && marketState.map((market, i) => (
                                                        market?.marketName === "WINNER" && market?.runners && market?.runners.length && market.runners.map((runner, i) => (
                                                            <div >
                                                                <div className="flex">
                                                                    <div className='text-[12px] font-bold w-[66px] rounded-tl-[100vmax] rounded-bl-[100vmax] bg-[#a7d8fd] cursor-pointer px-[1.5rem] p-[1rem] !items-center flex-col flex'>
                                                                        <span className='text-[12px] font-bold' >{runner.price.back[0].price}</span>
                                                                        <span className='text-[12px] font-bold' >{runner.price.back[0].size}</span>
                                                                    </div>
                                                                    <div className='text-[12px] font-bold w-[66px] rounded-tr-[100vmax] rounded-br-[100vmax] bg-[#f9c9d4] cursor-pointer px-[1.5rem] p-[1rem] !items-center flex-col flex'>
                                                                        <span className='text-[12px] font-bold' >{runner.price.lay[0].price}</span>
                                                                        <span className='text-[12px] font-bold' >{runner.price.lay[0].size}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))))
                                                }
                                            </li>
                                        </ol>

                                        <div className='mt-[.25rem] w-[100%] ml-[auto] mr-[auto]'>
                                            <div >
                                                {marketState && marketState.length > 0 && marketState.map((market, i) => (
                                                    market?.marketName === "PAIR" && market?.runners && market?.runners.length && market.runners.map((runner, i) => (
                                                        <div className='-ml-[5px] -mr-[5px] bg-[#f1f5f8] py-[5px] flex flex-wrap pr-[calc(1.5rem*.5)] pl-[calc(1.5rem*.5)]'>
                                                            <div className='w-[100%]'>
                                                                <button className='h-[60px] rounded-[22px] bg-[linear-gradient(-180deg,#14805e_0,_#184438_100%)] text-black p-[6px] text-[14px] w-[100%] relative uppercase px-[3rem] mt-[.25rem] flex items-center justify-between ' >
                                                                    {market?.runnersName[runner?.selectionId]}
                                                                    <b >{runner.price['back'][0].price}</b>
                                                                </button>
                                                                <p className='mb-0 p-0'>
                                                                    <span class="profitLoss-font"></span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))))}
                                                <div className='-ml-[5px] -mr-[5px] bg-[#f1f5f8] py-[5px] flex flex-wrap pr-[calc(1.5rem*.5)] pl-[calc(1.5rem*.5)]'>
                                                    <div className='w-[100%] text-right'>
                                                        <p className='h-[18px] leading-[18px] mb-0 mt-0'> Min:100 Max:100000 </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <ul className='flex w-[100%] bg-[linear-gradient(-180deg,#14805e_0,_#184438_100%)]'>
                                            <li className={`w-[50%] py-[7px] bg-[linear-gradient(-180deg,#14805e_0,_#184438_100%)] uppercase text-center text-black ${cardType == 'DRAGON CARD' && 'border-t-[2px] border-t-[#000]'} cursor-pointer`}>
                                                <a data-toggle="tab" className='cursor-pointer text-[#000] text-[16px] font-bold text-center w-[100%] flex items-center justify-center' onClick={() => setCardType('DRAGON CARD')}>Dragon</a>
                                            </li>
                                            <li className={`w-[50%] py-[7px] bg-[linear-gradient(-180deg,#14805e_0,_#184438_100%)] uppercase text-center text-black ${cardType == 'TIGER CARD' && 'border-t-[2px] border-t-[#000]'} cursor-pointer`}>
                                                <a data-toggle="tab" className='cursor-pointer text-[#000] text-[16px] font-bold text-center w-[100%] flex items-center justify-center' onClick={() => setCardType('TIGER CARD')}>Tiger</a>
                                            </li>
                                        </ul>

                                        <div className='pr-[calc(1.5rem*.2)] pl-[calc(1.5rem*0)] mt-[.5rem] mx-auto w-[100%]'>
                                            <div className='-ml-[5px] -mr-[5px] flex flex-wrap w-[100%]'>
                                                <div className='w-[50%] pr-[calc(1.5rem*.5)] pl-[calc(1.5rem*.5)]'>
                                                    <div>
                                                        <div className='-ml-[5px] -mr-[5px] bg-[#f1f5f8] py-[5px] flex flex-wrap w-[100%]'>
                                                            {marketState && marketState.length > 0 && marketState.map((market, i) => (
                                                                market?.marketName === "DRAGON ODD/EVEN" && market?.runners && market?.runners.length && market.runners.map((runner, i) => (
                                                                    <div className='w-[50%] pr-[calc(1.5rem*.5)] pl-[calc(1.5rem*.5)]'>
                                                                        <p className='h-[18px] text-center mb-0 block'>
                                                                            <b >{runner.price['back'][0].price}</b>
                                                                        </p>
                                                                        <button className='w-full h-[60px] rounded-[22px] bg-[linear-gradient(-180deg,#14805e_0,_#184438_100%)] text-black p-[6px] text-[14px] relative uppercase mt-[.25rem]'>
                                                                            {market?.runnersName[runner?.selectionId]}
                                                                        </button>
                                                                        <p className='mb-0'>
                                                                            <span> </span>
                                                                        </p>
                                                                    </div>
                                                                ))))}
                                                        </div>
                                                        <div className='-ml-[5px] -mr-[5px] bg-[#f1f5f8] py-[5px] flex flex-wrap w-[100%] pr-[calc(1.5rem*.5)] pl-[calc(1.5rem*.5)]'>
                                                            <div className='w-full text-right'>
                                                                <p class="mb-0 min-max h-18"> Min:100 Max:100000 </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='w-[50%] pr-[calc(1.5rem*.5)] pl-[calc(1.5rem*.5)]'>
                                                    <div>
                                                        <div className='-ml-[5px] -mr-[5px] bg-[#f1f5f8] py-[5px] flex flex-wrap w-[100%]'>
                                                            {marketState && marketState.length > 0 && marketState.map((market, i) => (
                                                                market?.marketName === "TIGER ODD/EVEN" && market?.runners && market?.runners.length && market.runners.map((runner, i) => (
                                                                    <div className='w-[50%] pr-[calc(1.5rem*.5)] pl-[calc(1.5rem*.5)]'>
                                                                        <p className='h-[18px] text-center mb-0 block'>
                                                                            <b >{runner.price['back'][0].price}</b>
                                                                        </p>
                                                                        <button className='w-full h-[60px] rounded-[22px] bg-[linear-gradient(-180deg,#14805e_0,_#184438_100%)]  p-[6px] text-[24px] relative uppercase mt-[.25rem]'>
                                                                            {market?.runnersName[runner?.selectionId] == 'TIGER ODD' ?
                                                                                <span className='text-[red]'> ♦ ♥ </span> :
                                                                                <span className='text-[black]'>♣ ♠</span>
                                                                            }
                                                                        </button>
                                                                        <p className='mb-0'>
                                                                            <span> </span>
                                                                        </p>
                                                                    </div>
                                                                ))))}
                                                        </div>
                                                        <div className='-ml-[5px] -mr-[5px] bg-[#f1f5f8] py-[5px] flex flex-wrap w-[100%] pr-[calc(1.5rem*.5)] pl-[calc(1.5rem*.5)]'>
                                                            <div className='w-full text-right'>
                                                                <p class="mb-0 min-max h-18"> Min:100 Max:100000 </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='pr-[calc(1.5rem*.2)] pl-[calc(1.5rem*0)] bg-[#f1f5f8] py-[5px] mt-[.5rem] w-[100%]'>
                                            <div className='-ml-[5px] -mr-[5px] flex flex-wrap w-[100%]'>
                                                <div className='h-[18px]  mr-[1.5rem] w-[41.66%] pr-[calc(1.5rem*.5)] pl-[calc(1.5rem*.5)]'>
                                                    <b className='uppercase'>{cardType}</b>
                                                </div>
                                                <div className='h-[18px] text-center justify-between flex w-[50%] pr-[calc(1.5rem*.5)] pl-[calc(1.5rem*.5)]'>
                                                    <b >12</b>
                                                    <div className='float-right'>
                                                        <i data-bs-toggle="dropdown" aria-expanded="false" className="fas fa-info-circle float-right" data-bs-target="#collapse129210110065012" id="#collapse129210110065012"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='-ml-[5px] -mr-[5px] mt-[.5rem] flex flex-wrap w-[100%]'>
                                                <div className='w-[100%] text-center pr-[calc(1.5rem*.2)] pl-[calc(1.5rem*0)]'>
                                                    {marketState && marketState.length > 0 && marketState.map((market, i) => (
                                                        market?.marketName === cardType && market?.runners && market?.runners.length && market.runners.map((runner, i) => (
                                                            <div className='mr-[10px] inline-block'>
                                                                <div>
                                                                    <img className='w-[40px]' src={`/t10cards/${market?.runnersName[runner?.selectionId]}.jpeg`}></img>
                                                                </div>
                                                                <div className='w-[40px] h-[18px] text-center text-[#198754]'></div>
                                                            </div>
                                                        ))))}
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div className='col-span-12 mt-[5px] bg-[#000] p-[5px] text-[#fff] inline-block overflow-x-auto mb-[100px] whitespace-nowrap'>
                                    <h5 className='inline-block font-black text-[1.07375rem] m-0'>Recent Result</h5>
                                    <ul className='inline-block whitespace-nowrap'>
                                        <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-black inline-block rounded-[15px] bg-[#72bbef] '>
                                            <button type='button' className='text-[#333]' onClick={showModal}>A</button>
                                        </li>
                                        <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-black inline-block rounded-[15px] bg-[#f9a9ba] '>
                                            <button type='button' className='text-[#333]' >B</button>
                                        </li>

                                        <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-black inline-block rounded-[15px] bg-[#f9a9ba] '>
                                            <button type='button' className='text-[#333]'>B</button>
                                        </li>

                                        <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-black inline-block rounded-[15px] bg-[#72bbef] '>
                                            <button type='button' className='text-[#333]' onClick={showModal}>A</button>
                                        </li>
                                        <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-black inline-block rounded-[15px] bg-[#72bbef] '>
                                            <button type='button' className='text-[#333]' onClick={showModal}>A</button>
                                        </li>
                                        <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-black inline-block rounded-[15px] bg-[#f9a9ba] '>
                                            <button type='button' className='text-[#333]'>B</button>
                                        </li>
                                        <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-black inline-block rounded-[15px] bg-[#f9a9ba] '>
                                            <button type='button' className='text-[#333]'>B</button>
                                        </li>
                                        <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-black inline-block rounded-[15px] bg-[#f9a9ba] '>
                                            <button type='button' className='text-[#333]'>B</button>
                                        </li>
                                    </ul>
                                    {/* <Modal className='recent-result-modal relative top-1 lg:top-7 w-[500px]' open={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel}>
                            <div className='grid grid-cols-12'>
                                <div className='col-span-12 flex rounded-t-[8px] items-center h-[45px] justify-between p-[10px] bg-[linear-gradient(-180deg,_#f6a21e_0%,_#cb7e05_100%)] border-[1px] border-[solid] border-[#ffffff] text-[#ffffff]'>
                                    <span className='leading-normal text-[.938rem] font-black'> 20-20 TEEN PATTI </span>
                                    <button type="button" className='text-[1.3rem] font-black'>×</button>
                                </div>
                                <div className='col-span-12 max-h-[80vh] overflow-y-auto '>
                                    <div className='grid grid-cols-12 mb-[80px]'>
                                        <div className='col-span-12 p-[.5rem]'>
                                            <h6 className='font-black text-[1rem] text-end'>Round Id: 9102906141719 </h6>
                                        </div>
                                        <div className='col-span-6 text-center border-r-[1px] border-r-[#ddd] py-[5px] my-[10px] float-left'>
                                            <h6 className='text-[1rem] font-black' >Player A</h6>
                                            <div className=''>
                                                <div className='flex justify-center'>
                                                    <img className='w-[62px] border-[1px] border-black rounded-[17px] m-[6px]' src="/cards/0.png"></img>
                                                    <img className='w-[62px] border-[1px] border-black rounded-[17px] m-[6px]' src="/cards/C9_.png"></img>
                                                    <img className='w-[62px] border-[1px] border-black rounded-[17px] m-[6px]' src="/cards/D10_.png"></img>
                                                </div>
                                                <button className='bg-[#28a745] py-[5px] px-[10px] text-[#fff] text-[20px] font-black block my-[10px] mx-auto rounded-[.375rem]'>Winner</button>
                                            </div>
                                        </div>
                                        <div className='col-span-6 text-center border-r-[1px] border-r-[#ddd] py-[5px] my-[10px] float-left'>
                                            <h6 className='text-[1rem] font-black' >Player B</h6>
                                            <div className=''>
                                                <div className='flex justify-center'>
                                                    <img className='w-[62px] border-[1px] border-black rounded-[17px] m-[6px]' src="/cards/0.png"></img>
                                                    <img className='w-[62px] border-[1px] border-black rounded-[17px] m-[6px]' src="/cards/C9_.png"></img>
                                                    <img className='w-[62px] border-[1px] border-black rounded-[17px] m-[6px]' src="/cards/D10_.png"></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-span-12 border-y-[1px] border-[#ccc] py-[10px]'>
                                            <h6 className='text-[.8rem] font-black text-center'>WINNER</h6>
                                            <div className="w-[50%] inline-block text-center text-[.75rem] p-[.25rem]">
                                                PLAYER A -
                                                <i className="fa fa-trophy winner-icon"></i>
                                            </div>
                                            <div className="w-[50%] inline-block text-center text-[.75rem] p-[.25rem]">
                                                PLAYER B -
                                                <i className="fa fa-trophy winner-icon loser-icon"></i>
                                            </div>
                                        </div>
                                        <div className='col-span-12 border-y-[1px] border-[#ccc] py-[10px]'>
                                            <h6 className='text-[.8rem] font-black text-center'>PAIR ( DUBBLE ) 1:4</h6>
                                            <div className="w-[50%] inline-block text-center text-[.75rem] p-[.25rem]">
                                                PLAYER A -
                                                <i className="fa fa-trophy winner-icon"></i>
                                            </div>
                                            <div className="w-[50%] inline-block text-center text-[.75rem] p-[.25rem]">
                                                PLAYER B -
                                                <i className="fa fa-trophy winner-icon loser-icon"></i>
                                            </div>
                                        </div>
                                        <div className='col-span-12 border-y-[1px] border-[#ccc] py-[10px]'>
                                            <h6 className='text-[.8rem] font-black text-center'>FLUSH ( COLOR ) 1:8</h6>
                                            <div className="w-[50%] inline-block text-center text-[.75rem] p-[.25rem]">
                                                PLAYER A -
                                                <i className="fa fa-trophy winner-icon"></i>
                                            </div>
                                            <div className="w-[50%] inline-block text-center text-[.75rem] p-[.25rem]">
                                                PLAYER B -
                                                <i className="fa fa-trophy winner-icon loser-icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal> */}
                                </div>
                            </div>
                        </div>
                    </div>

            }
        </>
    )
}
// export default Casino;
